import logo from './svg/logo.svg';
import home from './svg/home.svg';
import aboutUs from './svg/about-us.svg';
import howItWorks from './svg/how-it-works.svg';
import features from './svg/features.svg';
import faq from './svg/faq.svg';
import getapp from './svg/getapp.svg';
import homeT1 from './svg/homeT1.svg';
import planetknacktext from './svg/planetknacktext.svg';
import homeImage from './images/homeImage1.png';
import p2image1 from './images/p2image1.png';
import p3image1 from './images/p3image1.png';
import p3image2 from './images/p3image2.png';
import p3image3 from './images/p3image3.png';
import p3image4 from './images/p3image4.png';
import p3image5 from './images/p3image5.png';
import p3image6 from './images/p3image6.png';
import p3image7 from './images/p3image7.png';
import p3image8 from './images/p3image8.png';
import strip5logo from './svg/strip5logo.svg';
import strip6logo1 from './svg/strip6logo1.svg';
import p4image2 from './images/p4image2.png';
import p4image3 from './images/p4image3.png';
import p4image4 from './images/p4image4.png';
import p4image5 from './images/p4image5.png';
import p4image6 from './images/p4image6.png';
import android from './svg/android.svg';
import androidf from './svg/androidf.svg';
import ios from './svg/ios.svg';
import iosf from './svg/iosf.svg';
import './Web.css';
import ButtonBackground from './components/ButtonBackground';
import { useState, useRef } from 'react';
function Web() {
  const howItWorksRef = useRef(null);
  const homeRef = useRef(null);
  const featuresRef = useRef(null);
  const FAQRef = useRef(null);
  return (
    <div className="app" ref={homeRef}>
      <div className="header">
        <img src={logo} className="app-logo" alt="logo" />
        <div className="about-us">
          <ButtonBackground
            onClick={() => homeRef.current.scrollIntoView()}
            logo={home}
          ></ButtonBackground>
        </div>
        {/* <div className="about-us">
          <ButtonBackground logo={aboutUs}></ButtonBackground>
        </div> */}
        <div className="how-it-works">
          <ButtonBackground
            onClick={() => howItWorksRef.current.scrollIntoView()}
            logo={howItWorks}
          ></ButtonBackground>
        </div>
        <div className="features">
          <ButtonBackground
            onClick={() => featuresRef.current.scrollIntoView()}
            logo={features}
          ></ButtonBackground>
        </div>
        <div className="faq">
          <ButtonBackground
            onClick={() => FAQRef.current.scrollIntoView()}
            logo={faq}
          ></ButtonBackground>
        </div>
        <div className="getapp">
          <ButtonBackground
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.planettube'
              )
            }
            logo={getapp}
          ></ButtonBackground>
        </div>
        <div className="headerBoarder"></div>
      </div>
      <img src={homeT1} className="homeT1" alt="logo" />
      <div className="androidh">
        <ButtonBackground logo={android}></ButtonBackground>
      </div>
      <div className="iosh">
        <ButtonBackground logo={ios}></ButtonBackground>
      </div>
      <img src={planetknacktext} className="planetknacktext" alt="logo" />
      <img src={homeImage} className="homeImage" alt="logo" />
      <div className="part2">
        <h1 className="part2T1">BE A PART OF TALENT TREASURE</h1>
        <h1 className="part2T2">We're live in</h1>
        <h1 className="part2T4">Madhya Pradesh!</h1>
        <div className="part2T3"></div>
        <img src={p2image1} className="p2image1" alt="logo" />
      </div>
      <div className="p3strip1" ref={howItWorksRef}>
        <img src={p3image1} className="p3image1" alt="logo" />
      </div>
      <div className="p3strip2">
        <img src={p3image2} className="p3image2" alt="logo" />
        <h1 className="p3h1">Register yourself.</h1>
        <h1 className="p3h2">
          User can sign-in through their valid email id and password.
        </h1>
      </div>
      <img src={p3image3} className="p3image3" alt="logo" />
      <div className="p3strip3">
        <img src={p3image5} className="p3image5" alt="logo" />
        <h1 className="p3h3">Upload or Explore.</h1>
        <h1 className="p3h4">
          User can upload their talent and explore other's talent.
        </h1>
      </div>
      <img src={p3image4} className="p3image4" alt="logo" />
      <div className="p3strip4">
        <img src={p3image6} className="p3image6" alt="logo" />
        <h1 className="p3h5">Compete and Win.</h1>
        <h1 className="p3h6">
          User can take part in monthly contests and win big.
        </h1>
      </div>
      <img src={p3image7} className="p3image7" alt="logo" />
      <div ref={featuresRef} className="p3strip5">
        <img src={strip5logo} className="strip5logo" alt="logo" />
        <img src={strip5logo} className="strip5logo2" alt="logo" />
        <img src={strip5logo} className="strip5logo3" alt="logo" />
        <h1 className="strip5T1">SHOW THE TALENT</h1>
        <h1 className="strip5T2">EXPLORE THE TALENT</h1>
        <h1 className="strip5T3">SHOW THE TALENT</h1>
        <h1 className="strip5T4">EXPLORE THE TALENT</h1>
      </div>
      <img src={strip6logo1} className="strip6logo1" alt="logo" />
      <img src={p4image2} className="p4image2" alt="logo" />
      <img src={p4image3} className="p4image3" alt="logo" />
      <img src={p4image4} className="p4image4" alt="logo" />
      <img ref={FAQRef} src={p4image5} className="p4image5" alt="logo" />
      <img src={p4image6} className="p4image6" alt="logo" />
      <h1 className="faq1">1. How will I claim my rewards?</h1>
      <h1 className="faq1Ans">
        We will contact you using your registered email. Further we will send
        cheque by post.
      </h1>
      <h1 className="faq2">2. Why can't I see my video posted immediately?</h1>
      <h1 className="faq2Ans">
        We will review your video and it will be approved or rejected with in 24
        hours.
      </h1>
      <h1 className="faq3">3. When will I appear in studio?</h1>
      <h1 className="faq3Ans">
        After you win constantly in contests we will invite top 10 from
        different categories.
      </h1>
      <h1 className="faq4">
        4. How will I become famous from using planet knack?
      </h1>
      <h1 className="faq4Ans">
        There will be multiple promotions and live studio performances from
        which you will have large audience.
      </h1>
      <div className="footer">
        <h1 className="footerT1">
          Join us today and be a part of planet knack!
        </h1>
        <img src={androidf} className="android" alt="logo"></img>
        <img src={iosf} className="ios" alt="logo"></img>
        <div className="divider"></div>
        <h1 className="h1">Information</h1>
        <h1
          onClick={() =>
            window.open(
              'https://planet-knack-terms-of-use.s3.ap-south-1.amazonaws.com/index.html'
            )
          }
          className="h2"
        >
          Terms of Use
        </h1>
        <h1
          onClick={() =>
            window.open(
              'https://planet-knack-privacy-policies.s3.ap-south-1.amazonaws.com/index.html'
            )
          }
          className="h3"
        >
          Privacy Policy
        </h1>
        <h1 className="h4">Contact Us At info@planetknack.com</h1>
        <h1 className="h5">Quick Links</h1>
        <h1 onClick={() => homeRef.current.scrollIntoView()} className="h6">
          Home
        </h1>
        <h1
          onClick={() => howItWorksRef.current.scrollIntoView()}
          className="h7"
        >
          How it Works
        </h1>
        <h1 onClick={() => featuresRef.current.scrollIntoView()} className="h8">
          Features
        </h1>
        <h1 onClick={() => FAQRef.current.scrollIntoView()} className="h9">
          FAQ
        </h1>
      </div>
      <div className="copyRight">
        <h1 className="hCopyRights">
          © 2023 Planet Knack. All Rights Reserved.
        </h1>
      </div>
    </div>
  );
}

export default Web;
