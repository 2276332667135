import logo from './svg/logo.svg';
import home from './svg/home.svg';
import aboutUs from './svg/about-us.svg';
import howItWorks from './svg/how-it-works.svg';
import features from './svg/features.svg';
import faq from './svg/faq.svg';
import getapp from './svg/getapp.svg';
import homeT1M from './svg/homeT1M.svg';
import planetknacktext from './svg/planetknacktext.svg';
import homeimagem from './images/homeimagem.png';
import p2image1 from './images/p2image1.png';
import p3image1 from './images/p3image1.png';
import p3image2 from './images/p3image2.png';
import p3image3 from './images/p3image3.png';
import p3image4 from './images/p3image4.png';
import p3image5 from './images/p3image5.png';
import p3image6 from './images/p3image6.png';
import p3image7 from './images/p3image7.png';
import p3image8 from './images/p3image8.png';
import strip5logo from './svg/strip5logo.svg';
import strip6logo1 from './svg/strip6logo1.svg';
import p4image2 from './images/p4image2.png';
import p4image3 from './images/p4image3.png';
import p4image4 from './images/p4image4.png';
import p4image5 from './images/p4image5.png';
import p4image6 from './images/p4image6.png';
import android from './svg/android.svg';
import androidf from './svg/androidf.svg';
import ios from './svg/ios.svg';
import iosf from './svg/iosf.svg';
import ButtonBackground from './components/ButtonBackground';
import { useState, useRef } from 'react';
import './Mobile.css';
export default function Mobile() {
  return (
    <div>
      <div className="headerM">
        <img src={logo} className="app-logoM" alt="logo" />
        <div className="getappM">
          <ButtonBackground
            onClick={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.planettube'
              )
            }
            logo={getapp}
            style={{ height: '37px' }}
          ></ButtonBackground>
        </div>
        <div className="headerBoarderM"></div>
      </div>
      <img src={homeT1M} className="homeT1M" alt="logo" />
      <img src={homeimagem} className="homeImageM" alt="logo" />
      <div className="androidhM">
        <ButtonBackground
          logo={android}
          style={{ height: '40px' }}
        ></ButtonBackground>
      </div>
      <div className="ioshM">
        <ButtonBackground
          logo={ios}
          style={{ height: '40px' }}
        ></ButtonBackground>
      </div>
      <img src={planetknacktext} className="planetknacktextM" alt="logo" />
      <div className="part2M">
        <h1 className="part2T1M">BE A PART OF TALENT TREASURE</h1>
        <h1 className="part2T2M">We're live in</h1>
        <h1 className="part2T4M">Madhya Pradesh!</h1>
        <div className="part2T3M"></div>
        <img src={p2image1} className="p2image1M" alt="logo" />
      </div>
      <div className="p3strip1M">
        <img src={p3image1} className="p3image1M" alt="logo" />
      </div>
      <div className="p3strip2M">
        <img src={p3image2} className="p3image2M" alt="logo" />
        <h1 className="p3h1M">Register yourself.</h1>
        <h1 className="p3h2M">
          User can sign-in through their valid email id and password.
        </h1>
      </div>
      <img src={p3image3} className="p3image3M" alt="logo" />
      <div className="p3strip3M">
        <img src={p3image5} className="p3image5M" alt="logo" />
        <h1 className="p3h3M">Upload or Explore.</h1>
        <h1 className="p3h4M">
          User can upload their talent and explore other's talent.
        </h1>
      </div>
      <img src={p3image4} className="p3image4M" alt="logo" />
      <div className="p3strip4M">
        <img src={p3image6} className="p3image6M" alt="logo" />
        <h1 className="p3h5M">Compete and Win.</h1>
        <h1 className="p3h6M">
          User can take part in monthly contests and win big.
        </h1>
      </div>
      <img src={p3image7} className="p3image7M" alt="logo" />
      <img src={strip6logo1} className="strip6logo1M" alt="logo" />
      <img src={p4image2} className="p4image2M" alt="logo" />
      <img src={p4image3} className="p4image3M" alt="logo" />
      <img src={p4image4} className="p4image4M" alt="logo" />
      <img src={p4image5} className="p4image5M" alt="logo" />
      <img src={p4image6} className="p4image6M" alt="logo" />
      <h1 className="faq1M">1. How will I claim my rewards?</h1>
      <h1 className="faq1AnsM">
        We will contact you using your registered email. Further we will send
        cheque by post.
      </h1>
      <h1 className="faq2M">2. Why can't I see my video posted immediately?</h1>
      <h1 className="faq2AnsM">
        We will review your video and it will be approved or rejected with in 24
        hours.
      </h1>
      <h1 className="faq3M">3. When will I appear in studio?</h1>
      <h1 className="faq3AnsM">
        After you win constantly in contests we will invite top 10 from
        different categories.
      </h1>
      <h1 className="faq4M">
        4. How will I become famous from using planet knack?
      </h1>
      <h1 className="faq4AnsM">
        There will be multiple promotions and live studio performances from
        which you will have large audience.
      </h1>
      <div className="footerM">
        <h1 className="footerT1M">
          Join us today and be a part of planet knack!
        </h1>
        <h1
          onClick={() =>
            window.open(
              'https://planet-knack-terms-of-use.s3.ap-south-1.amazonaws.com/index.html'
            )
          }
          className="h2M"
        >
          Terms of Use
        </h1>
        <h1
          onClick={() =>
            window.open(
              'https://planet-knack-privacy-policies.s3.ap-south-1.amazonaws.com/index.html'
            )
          }
          className="h3M"
        >
          Privacy Policy
        </h1>
        <h1 className="h4M">Contact Us At info@planetknack.com</h1>
      </div>
    </div>
  );
}
