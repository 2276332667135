import React from 'react';
import './ButtonBackground.css';

export default function ButtonBackground({
  logo,
  onClick = () => {},
  style = {},
}) {
  return (
    <button className="Button" onClick={onClick}>
      <img src={logo} alt="logo" style={style} />
    </button>
  );
}
